var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("awaris-account")))]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "ic_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm.trust ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.trust.user.ic_number) + " ")]) : _vm._e(), !_vm.trust ? _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "readonly": _vm.trust,
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }) : _vm._e(), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "hibah_type_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.type")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.hibahTypeString))])])])]), _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }